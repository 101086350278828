<template>
    <div class="">upgrade</div>
</template>

<script>
export default {
    name: 'LicenseUpgrade',
    data: () => ({
    }),
    computed: {
    },
    props: [],
    watch: {
    },
    methods: {
    },
    created() {
    },
    beforeDestroy(){
    },
    mounted() {
    },
    components: {
    },
}
</script>